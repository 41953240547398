import React from 'react';
import { NavLink } from 'react-router-dom';

export const HeaderNav = () => {
  return (
    <header className='header'>
        <span className='logo'>
             <div className='logo-image'><img src="./images/foto.png" alt="Foto personal" /></div>
             <div className='logo-text'>
                Diego Morante <br /> {'{Desarrollador Web}'}
             </div>
        </span>
        
        <nav>
            <ul>
                <li>
                    <NavLink to="/Inicio" className={({isActive}) => isActive ? "active" : ""}>Inicio</NavLink>
                </li>
                <li>
                    <NavLink to="/portafolio" className={({isActive}) => isActive ? "active" : ""}>Portafolio</NavLink>
                </li>
                <li>
                    <NavLink to="/habilidades" className={({isActive}) => isActive ? "active" : ""}>Habilidades</NavLink>
                </li>
                <li>
                    <NavLink to="/curriculum" className={({isActive}) => isActive ? "active" : ""}>Curriculum</NavLink>
                </li>
                <li>
                    <NavLink to="/contacto" className={({isActive}) => isActive ? "active" : ""}>Contacto</NavLink>
                </li>
            </ul>
        </nav>
    </header>
  )
}
