import React, { useState } from 'react';

const TrabajoDescription = ({ descripcion }) => {
  const lines = descripcion.split('\n');
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const displayedDescription = showFullDescription ? lines : lines.slice(0, 1);

  // Función para envolver texto entre ** en negrita
  const formatText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  return (
    <div>
      {displayedDescription.map((line, index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: formatText(line) }} />
      ))}
      {lines.length > 1 && (
        <button onClick={toggleDescription}>
          {showFullDescription ? 'Mostrar menos' : 'Leer más'}
        </button>
      )}
    </div>
  );
};

export default TrabajoDescription;
