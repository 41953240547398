import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { trabajos } from '../../data/trabajos';

export const Proyecto = () => {
    const [proyecto, setProyecto] = useState({});
    const params = useParams();

    useEffect(() => {
        let proyecto = trabajos.filter(trabajo => trabajo.id === params.id);
        setProyecto(proyecto[0]);
    }, [params.id]);

    const formatText = (text) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    };

    const formattedDescription = proyecto.descripcion
        ? proyecto.descripcion.split('\n').map((line, index) => {
            const formattedLine = formatText(line); 
            return (
                <span key={index}>
                    <span dangerouslySetInnerHTML={{ __html: formattedLine }}></span>
                    <br />
                </span>
            );
        })
    : null;

    return (
            <div className='page page-work'>
              <a href={"https://" + proyecto.url} target="_blank" rel="noreferrer" className='mask'>
                <img src={"/images/" + proyecto.id + ".png"} alt={proyecto.nombre} />
              </a>
              <h1 className='heading'>{proyecto.nombre}</h1>
              <p>{proyecto.tecnologias}</p>
              <p className="custom-description">
                {formattedDescription}
              </p>
              <button onClick={() => window.open("https://" + proyecto.url, "_blank", "noreferrer")}>Ir a la web</button>
              <br/>
            </div>
    );
          
          
};

