import React from 'react';
import { ListadoTrabajos } from './layout/ListadoTrabajos';

export const Portafolio = () => {
  return (
    <div className='page'>
      <h1 className='heading'>Portafolio</h1>
      <h3>Estos son mis proyectos actuales, próximamente se irán añadiendo más.</h3>
      <ListadoTrabajos />
    </div>
  )
}
