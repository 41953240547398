import React from 'react'

export const Habilidades = () => {
  return (
    <div className='page'>
      <h1 className='heading'>Habilidades</h1>

      <section className='services'>

        <article className='service'>
          <h2>Java</h2>
          <img src="./images/java.png" alt="Logo de Java" />
        </article>

        <article className='service'>
          <h2>Javascript</h2>
          <img src="./images/js.png" alt="Logo de JS" />
        </article>

        <article className='service'>
          <h2>React</h2>
          <img src="./images/react.png" alt="Logo de React" />
        </article>

        <article className='service'>
          <h2>HTML CSS</h2>
          <img src="./images/htmlcss.png" alt="Logo de HTML y CSS" />
        </article>

        

        <article className='service'>
          <h2>Python</h2>
          <img src="./images/python.png" alt="Logo de Python" />
        </article>

        <article className='service'>
          <h2>BDD SQL</h2>
          <img src="./images/SQL.png" alt="Logo de SQL" />
        </article>

      </section>
    </div>
  )
}
