import React from 'react'
import { Link } from 'react-router-dom'
import { ListadoTrabajos } from './layout/ListadoTrabajos'

export const Inicio = () => {
  return (
    <div className='home'>

        <h1>
          Hola, soy <strong>Diego Morante</strong>, estudiante de Desarrollo de Aplicaciones Web y este es mi portafolio creado con React. 
          <br/>
          <br/>
          Esta página se encuentra en construcción, próximamente ire añadiendo nuevos proyectos de <strong>desarrollo web.</strong>
        </h1>

        <h2 className='title'>
          Si buscas un programador junior con muchas ganas de aprender, estaré encantado de que te pongas en contacto conmigo. <Link to="/contacto">Contacta</Link>
        </h2>

        <section className='last-works'>
          <h2 className='heading'>Algunos de mis proyectos</h2>
          <h2>Estos son algunos de mis trabajos</h2>

          <ListadoTrabajos limite="2" />

        </section>

    </div>
  )
}
