import React from 'react';
import { Link } from 'react-router-dom';
import { trabajos } from '../../data/trabajos';
import TrabajoDescription from '../TrabajoDescription';

export const ListadoTrabajos = ({ limite }) => {
  return (
    <section className='works'>
      {trabajos.slice(0, limite).map((trabajo) => (
        <article key={trabajo.id} className='work-item'>
          <Link to={"/proyecto/" + trabajo.id} className="work-link button-link">
            <div className='mask'>
              <img src={"/images/" + trabajo.id + ".png"} alt={trabajo.nombre} />
            </div>
           <span>{trabajo.categorias}</span>
           <h2 className="work-title">
              {trabajo.nombre} - Ir al Proyecto
           </h2>
          </Link>

          <TrabajoDescription descripcion={trabajo.descripcion} />
          <h3>{trabajo.tecnologias}</h3>
        </article>
      ))}
    </section>
  )
}

