import React from 'react';
import { useForm } from 'react-hook-form';

export const Contacto = () => {
  const { register, formState: { errors }, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const mailtoLink = `mailto:diego_morante@outlook.es?subject=${encodeURIComponent(data['Motivo de contacto'])}&body=${encodeURIComponent(`Nombre: ${data['nombre']}\nApellidos: ${data['apellidos']}\nEmail: ${data['email']}\nMotivo de contacto: ${data['Motivo de contacto']}`)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className='page'>
      <h1 className='heading'>Contacto</h1>

      <form onSubmit={handleSubmit(onSubmit)} className='contact'>
        <input
          type='text'
          {...register('nombre', {
            required: 'Es necesario rellenar el campo nombre',
          })}
          className="input-field"
          placeholder='Nombre'
        />
        {errors.nombre && <p>{errors.nombre.message}</p>}

        <input
          type='text'
          {...register('apellidos')}
          className="input-field"
          placeholder='Apellidos'
        />

        <input
          type='text'
          {...register('email', {
            required: 'Es necesario rellenar el campo email',
            pattern: {
              value: /^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/,
              message: 'Formato de correo electrónico no válido',
            },
          })}
          className="input-field"
          placeholder='Email'
        />
        {errors.email && <p>{errors.email.message} </p>}

        <textarea
          {...register('Motivo de contacto')}
          className="input-field"
          placeholder='Motivo de contacto'
        />

        <input type="submit" value="Enviar" className="submit-button" />
      </form>
    </div>
  );
};

