import React from 'react';

export const Curriculum = () => {
  return (
    <div className='page curriculum'>
      <span className='heading-curriculum'>Diego Morante <br /> &lt;Desarrollador Web/&gt;</span>

      <div className='column'>
        <h2>Experiencia Laboral</h2>
        <ul>
          <li>
            <strong>OPERARIO DE PRODUCCIÓN POLIVALENTE</strong><br />
            MARTINREA HONSEL SPAIN. | JUNIO 2017 - ACTUALIDAD<br />
            Operario de línea automatizada con almacén inteligente
            <ul>
              <li>Manejo y gestión del almacén.</li>
              <li>Realizar algunas labores de mantenimiento de la línea. Fundidor polivalente</li>
              <li>Fundición de cualquiera de las referencias.</li>
              <li>Amplia experiencia y conocimientos sobre el proceso.</li>
            </ul>
          </li>
          <br />
          <li>
            <strong>MECÁNICO Y ELECTROMECÁNICO</strong><br />
            FORD | 2017 PRÁCTICAS FCT DE AUTOMOCIÓN 3 MESES<br />
            Mecánica general y mantenimientos.
            <br />
            TECNOMOCIÓN | MESES DE VERANO 2015 Y 2016<br />
            Diagnosis de vehículos y reparación.
          </li>
          <br />
          <li>
            <strong>PINTOR Y DECORADOR</strong><br />
            ANTONIO SUÁREZ | 2010 - 2016<br />
            Preparación y pintado de todo tipo de superficies. Realizar labores de encargado de obra.
            <ul>
              <li>Planificación del trabajo y dirección del personal.</li>
            </ul>
          </li>
        </ul>

        <h2>Educación</h2>
        <ul>
          <li>
            <strong>GRADO SUPERIOR DE DESARROLLO DE APLICACIONES WEB</strong><br />
            CESUR | FEBRERO 2022 HASTA LA ACTUALIDAD
          </li>
          <br />
          <li>
            <strong>GRADO SUPERIOR DE AUTOMOCIÓN</strong><br />
            IES JUANELO TURRIANO | 2015 - 2017
          </li>
        </ul>
      </div>

      <div className='column'>
        <h2>Formación Complementaria</h2>
         <strong>CURSOS DE PYTHON CON CERTIFICACIÓN GOOGLE</strong>
            <ul>
              <li>Crash course on Python</li>
              <li>Using Python to Interact with the Operating System</li>
              <li>Introduction to Git and GitHub</li>
              <li>Troubleshooting and Debugging Techniques</li>
              <li>Configuration Management and the Cloud</li>
              <li>Automating Real-World Tasks with Python</li>
        </ul>

        <h2>Aptitudes</h2>
        <ul>
          <li>Trabajo en equipo</li>
          <li>Resolución de problemas</li>
          <li>Adaptabilidad</li>
          <li>Comunicación efectiva</li>
          <li>Capacidad de aprendizaje</li>
        </ul>

        <h2>Habilidades</h2>
        <ul>
          <li>Java</li>
          <li>Javascript con React</li>
          <li>Python</li>
          <li>PHP</li>
          <li>HTML y CSS</li>
          <li>Bases de datos SQL</li>
        </ul>
      </div>
    </div>
  );
};
